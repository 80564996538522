import PropTypes from "prop-types"
import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { IconContext } from "@react-icons/all-files";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";

const Footer = ({ siteTitle }) => {

  const [impressum, setImpressum] = useState({})
  const handleChange = (e) => {
    let isChecked = e.target.checked;
    setImpressum({
      ...impressum,
      [e.target.name]: isChecked
    });
  }

  return (
  <footer className="main-footer-bar-wrap">
    <div className="main-footer-bar-wrap-first">
      <div className="ast-container">
        <div className="ast-flex ast-flex-start">
          <div className="footer-row footer-row-logo">
            <StaticImage
              src="../images/logo_white_2023.svg"
              alt="cookiebot logo"
              className="footer-logo"
              width={340}
            />
            <div className="footer-row-logo-sns">
              <div>
                <a href="https://de.linkedin.com/company/classmethodeurope" target="_blank" rel="noreferrer">
                  <IconContext.Provider value={{ className: "icon-sns-footer" }}>
                    <FaLinkedin />
                  </IconContext.Provider>
                </a>
                <a href="https://www.facebook.com/classmethod.de/" target="_blank" rel="noreferrer">
                  <IconContext.Provider value={{ className: "icon-sns-footer" }}>
                    <FaFacebookSquare />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
            <div>
              <img
                src="../images/Partner-Badges-Certified.png"
                alt="cookiebot partner certification"
                className="footer-cert"
                width={180}
              />
            </div>
          </div>
          <div className="footer-row footer-row-description">
            <p>コスト削減と内製化で企業のデジタル化を支えるテックカンパニー</p>
            <p>私たちはクラウド、モバイル、データ分析、機械学習、IoT、モダン開発などの技術を組み合わせ2,300以上の顧客を支援してきました。<br />
            「オープンな発想と高い技術力によって全ての人々の創造活動に貢献する」を理念に、技術支援や2万5千本の技術記事をはじめとするアウトプットを続けています。</p>
          </div>
          <div className="footer-row footer-row-group">
            <div>
              <h6><a href="http://classmethod.de" target="_blank" rel="noreferrer">クラスメソッド ヨーロッパ</a></h6>
              <p>
                Classmethod (Europe) GmbH<br />
                Krausenstr. 9-10<br /> 
                10117 Berlin, Germany
              </p>
            </div>
            <h6><a href="http://classmethod.jp" target="_blank" rel="noreferrer">クラスメソッド株式会社</a></h6>
            <h6><a href="https://www.classmethod.ca" target="_blank" rel="noreferrer">クラスメソッド カナダ</a></h6>
            <h6><a href="https://www.classmethod.co.th/" target="_blank" rel="noreferrer">クラスメソッド タイ</a></h6>
            <h6><a href="https://www.classmethodindia.co.in/" target="_blank" rel="noreferrer">クラスメソッド インド</a></h6>
          </div>
          <div className="footer-row footer-row-partner">
            <h6>パートナーサイト<br /><a href="https://www.cookiebot.com/" target="_blank" rel="noreferrer">Cookiebot</a></h6>
          </div>
        </div>
      </div>
    </div>
    <div className="main-footer-bar-wrap-second">
      <div className="ast-container">
        <div className="ast-flex">
          <h6>
            <input
              type="checkbox"
              name="impressum"
              id="impressum"
              onChange={handleChange}
              className="checkbox-faq"
            />
            <label htmlFor="impressum">
              Impressum
            </label>
          </h6>
          <span className="separator"> | </span>
          <h6><a href="https://www.classmethod.de/cookie-policy.html" target="_blank" rel="noreferrer">Cookie Policy</a></h6>
          <span className="separator"> | </span>
          <h6><a href="https://www.classmethod.de/gtm.html" target="_blank" rel="noreferrer">Terms and Conditions</a></h6>
        </div>
        {impressum.impressum &&
          <div>
            <StaticImage
              src="../images/impressum.png"
              alt="classmethod imressum"
              className="footer-impressum"
              width={250}
            />
          </div>
        }
        <div className="hfe-copyright-wrapper">
          <p>Copyright © Classmethod Inc.,<br className="brake-sp" /> and Classmethod (Europe) GmbH</p>
        </div>
      </div>
    </div>
  </footer>
)}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
