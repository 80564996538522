import React, {useEffect} from "react";

const HubspotContactForm = props => {
    //const { region, portalId, formId } = props;
    const loadScript = () => {
        return new Promise(function (resolve, reject) {
            const script = document.createElement('script');
            script.charset='utf-8';
            script.src='https://js.hsforms.net/forms/v2.js';
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);

            script.addEventListener('load', () => {
                if (window.hbspt) {
                    window.hbspt.forms.create({
                        //region: {region},
                        //portalId: {portalId},
                        //formId: {formId},
                        region: "na1",
                        portalId: "3871806",
                        formId: 'c230d31d-a249-444e-b368-1c137282540e',
                        target: '#hubspotForm'
                    })
                }
            });
        });
    }
    useEffect(() => {
        loadScript()
    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );
};

export default HubspotContactForm;